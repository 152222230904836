import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from './Statistics.module.scss';
import { Grid, GridCol, GridRow } from "@sb1/ffe-grid-react";
import WeightedRate from "./Cards/WeightedRate/WeightedRate";
import Volume from "./Cards/Volume/Volume";
import Cases from "./Cards/Cases/Cases";
import AvgDuration from "./Cards/AvgDuration/AvgDuration";
import CaseCountEK from "./Cards/CaseCountEK/CaseCountEK";
import EKLoanPercentage from "./Cards/EKLoanPercentage/EKLoanPercentage";
import SalesmanSalesYear from "./Cards/SalesmanSalesYear/SalesmanSalesYear";
import SalesmanSalesMonth from "./Cards/SalesmanSalesMonth/SalesmanSalesMonth";
import PointsOverall from "./Cards/PointsOverall/PointsOverall";
import PointsRegionYear from "./Cards/PointsRegionYear/PointsRegionYear";
import YearCompare from "./Cards/YearCompare/YearCompare";
import PointsMonth from "./Cards/PointsMonth/PointsMonth";
import Top10Dealers from "./Cards/Top10Dealers/Top10Dealers";
import { toast } from 'react-toastify';
import { useAuth } from "../../../Auth";
import { useAxios } from "../../../AxiosHandler";
import general from "../../../functions/general";
import PointsTableDynamic from "./Cards/PointsTableDynamic/PointsTableDynamic";
import Spinner from "@sb1/ffe-spinner-react";
import FullscreenLoading from "../../Shared/FullscreenLoading/FullscreenLoading";

function Statistics() {
    const auth = useAuth();
    const axiosHandler = useAxios();
    const location = useLocation();
    const axios = axiosHandler.axios;

    function completeDataSet(objects: any, desiredNoOfKeys: number, startKey = 1){
        let currentYear = new Date().getFullYear();
        let years = [currentYear, currentYear - 1];
        for(const year of years){
            let foundYearObjects = objects.filter((object: any) => object.year == year);
            if(foundYearObjects.length < desiredNoOfKeys) {
                for(let i = startKey; i <= desiredNoOfKeys; i++) {
                let foundObject = foundYearObjects.find((object: any) => object.month == i );
                if(foundObject == null) {
                    objects.push({year: year, month: i, sum: null, count: null});
                }
                }
            }  
        }

        return objects.sort((a: any, b: any) => a.month - b.month);
        
    }

    const [volume, setVolume] = useState<any>(undefined);
    const [cases, setCases] = useState<any>(undefined);
    const [keydata, setKeydata] = useState<any>([]);
    const [topLists, setTopLists] = useState<any>([]);
    const [loadingKeydata, setLoadingKeydata] = useState(true);
    const [loadingToplists, setLoadingToplists] = useState(true);
    const [loadingYearlydata, setLoadingYearlydata] = useState(true);

    /* const [weightedRate, setWeightedRate] = useState(); */
    /* const [yearCompare, setYearCompare] = useState(); */
    /* const [avgDuration, setAvgDuration] = useState();*/
    /* const [caseCountEK, setCaseCountEK] = useState();  */
    /* const [yearlyData, setYearlyData] = useState<any>(undefined); */
    /* const [eKLoanPercentage, setEKLoanPercentage] = useState(); */

    /* function fetchCaseCountEK() {
        axios.single({
            url: "/api/statistics/casecountek" + "?dealerSequence=" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setCaseCountEK(res.data);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av antall saker feilet! Vennligst prøv igjen");
        });
    }

    function fetchWeightedRate() {
        axios.single({
            url: "/api/statistics/weightedrate" + "?dealerSequence=" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setWeightedRate(res.data);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av antall saker feilet! Vennligst prøv igjen");
        });
    }

    function fetchVolume() {
        axios.single({
            url: "/api/statistics/volume" + "?dealerSequence=" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setVolume(res.data);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av antall saker feilet! Vennligst prøv igjen");
        });
    }

    function fetchYearcompare() {
        axios.single({
            url: "/api/statistics/yearcompare" + "?dealerSequence=" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setYearCompare(res.data);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av antall saker feilet! Vennligst prøv igjen");
        });
    }

    function fetchCases() {
        axios.single({
            url: "/api/statistics/cases" + "?dealerSequence=" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setCases(res.data);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av antall saker feilet! Vennligst prøv igjen");
        });
    }

    function fetchAvgDuration() {
        axios.single({
            url: "/api/statistics/avgduration" + "?dealerSequence=" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setAvgDuration(res.data);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av antall saker feilet! Vennligst prøv igjen");
        });
    }

    function fetchEKLoanPercentage() {
        axios.single({
            url: "/api/statistics/ekloanpercentage" + "?dealerSequence=" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            setEKLoanPercentage(res.data);
        }).catch((err: any) => {
            console.log(err);
            toast.dismiss();
            toast.error("Henting av antall saker feilet! Vennligst prøv igjen");
        });
    } */

    function fetchKeydata() {
        axios.single({
            url: "/api/statistics/keydata/" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if(general.isError(res).isError === true) {
                toast.error("Henting av nøkkeltdata feilet! " + general.isError(res).errorMessage);
                setKeydata(undefined);
                return;
            }
            if(res?.data?.[0]?.Data == undefined) {
                setKeydata(undefined);
                return;
            }
            setKeydata(JSON.parse(res.data[0].Data));
            setLoadingKeydata(false);
            
        }).catch((err: any) => {
            setKeydata(undefined);
            setLoadingKeydata(false);
            console.log(err);
            toast.dismiss();
            toast.error("Henting av nøkkeltdata feilet! Vennligst prøv igjen");
        });
    }
    function fetchToplists() {
        axios.single({
            url: "/api/statistics/toplists/" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if(general.isError(res).isError === true) {
                toast.error("Henting av nøkkeltdata feilet! " + general.isError(res).errorMessage);
                return;
            }
            if(res?.data?.[0]?.Data == undefined) {
                setTopLists([]);
                return;
            }

            let parsedData = JSON.parse(res.data[0].Data);
            setTopLists(parsedData);
            setLoadingToplists(false);
            
        }).catch((err: any) => {
            setLoadingToplists(false);
            console.log(err);
            toast.dismiss();
            toast.error("Henting av nøkkeltdata feilet! Vennligst prøv igjen");
        });
    }
    function fetchYearlydata() {
        axios.single({
            url: "/api/statistics/yearly/" + localStorage.getItem("selectedDealerSequence"),
            method: "GET",
            headers: {
                "UserAuthorization": "Bearer " + (JSON.parse(localStorage.getItem("logindata") ?? "")["JWT"])
            }
        }).then((res: any) => {
            if(general.isError(res).isError === true) {
                toast.error("Henting av årlig data feilet! " + general.isError(res).errorMessage);
                setVolume(undefined);
                setCases(undefined);
                return;
            }
            if(res?.data?.[0]?.Data == undefined) {
                setVolume(undefined);
                setCases(undefined);
                return;
            }

            let currentYear = new Date().getFullYear();

            let parsedData = JSON.parse(res.data[0].Data);

            parsedData = completeDataSet(parsedData, 12);
            
            let sumDataLastYear = parsedData.filter((d: any) => d.year === (currentYear - 1)).sort((a: any, b: any) => a.month - b.month).map((i: any) => i.sum);
            let sumDataCurrentYear = parsedData.filter((d: any) => d.year === currentYear).sort((a: any, b: any) => a.month - b.month).map((i: any) => i.sum);
            let casesDataLastYear = parsedData.filter((d: any) => d.year === (currentYear - 1)).sort((a: any, b: any) => a.month - b.month).map((i: any) => i.count);
            let casesDataCurrentYear = parsedData.filter((d: any) => d.year === currentYear).sort((a: any, b: any) => a.month - b.month).map((i: any) => i.count);

            if(sumDataLastYear.every((v: any) => v == null) && sumDataCurrentYear.every((v: any) => v == null)){
                setVolume(undefined);
            } else {
                setVolume({
                    target: sumDataLastYear,
                    value: sumDataCurrentYear 
                });
            }
            
            if(casesDataLastYear.every((v: any) => v == null) && casesDataCurrentYear.every((v: any) => v == null)){
                setCases(undefined);
            } else {
                setCases({
                    target: casesDataLastYear,
                    value: casesDataCurrentYear
                });
            }

            setLoadingYearlydata(false);

        }).catch((err: any) => {
            setVolume(undefined);
            setCases(undefined);
            setLoadingYearlydata(false);
            console.log(err);
            toast.dismiss();
            toast.error("Henting av årlig data feilet! Vennligst prøv igjen");
        });
    }

    function fetchData() {
        fetchKeydata();
        fetchToplists();
        fetchYearlydata();
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className={styles.statistics}>
            {loadingKeydata || loadingToplists || loadingYearlydata ?
                <FullscreenLoading />
            :
                <Grid className={styles.grid}>
                    <GridRow className={styles.gridrow + ((keydata == undefined && volume == undefined && cases == undefined) ? (" " + styles.blurred) : "")}>
                        <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* WeightedRate */}
                            {keydata == undefined || (keydata?.target_weightedrate == undefined && keydata?.weightedrate == undefined) ? null :
                                <WeightedRate
                                    data={{
                                        target: keydata.target_weightedrate,
                                        value: keydata.weightedrate
                                    }}
                                />
                            }
                        </GridCol>
                        <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* CasesCount */}
                            {cases == undefined ? null :
                                <Cases
                                    data={cases}
                                />
                            }
                        </GridCol>
                        <GridCol className={styles.gridcol} lg="9" md="12" sm="12">{/* Volume */}
                            {volume == undefined ? null :
                                <Volume
                                    data={volume}
                                />
                            }
                        </GridCol>
                        <GridCol className={styles.gridcol} lg="3" md="12" sm="12">{/* Volume */}
                            {volume == undefined ? null :
                                <YearCompare
                                    data={{
                                        value: [(volume?.target?.reduce((a: any, b: any) => a + b, 0) ?? 0), (volume?.value?.reduce((a: any, b: any) => a + b, 0) ?? 0)],
                                        year: [(new Date().getFullYear() - 1), new Date().getFullYear()]
                                    }}
                                />
                            }
                        </GridCol>
                        <GridCol className={styles.gridcol} lg="12" md="12" sm="12">{/* AvgDuration */}
                            {keydata == undefined || (keydata?.target_avgduration == undefined && keydata?.avgduration == undefined) ? null :
                                <AvgDuration
                                    data={{
                                        target: Number(keydata.target_avgduration),
                                        value: Number(keydata.avgduration)
                                    }}
                                />
                            }
                        </GridCol>
                        <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* CaseCountEK */}
                            {keydata == undefined || (keydata?.target_percentage_own_capital == undefined && keydata?.percentage_own_capital == undefined) ? null :
                                <CaseCountEK
                                    data={{
                                        target: Number(((keydata?.target_percentage_own_capital ?? 0) * 100).toFixed(2)),
                                        value: Number(((keydata?.percentage_own_capital ?? 0) * 100).toFixed(2))
                                    }}
                                />
                            }
                        </GridCol>
                        <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* EKLoanPercentage */}
                            {keydata == undefined || (keydata?.target_avg_own_capital == undefined && keydata?.avg_own_capital == undefined) ? null :
                                <EKLoanPercentage
                                    data={keydata ? {
                                        target: Number(((keydata.target_avg_own_capital?? 0) * 100).toFixed(2)),
                                        value: Number(((keydata.avg_own_capital?? 0) * 100).toFixed(2))
                                    } : undefined}
                                />
                            }
                        </GridCol>
                    </GridRow>
                    <GridRow className={styles.gridrow}>
                        {topLists?.length == 0 || topLists?.[0]?.toplist_local?.length == 0 ? null :
                            <>
                                <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* SalesmanSalesYear */}
                                    <SalesmanSalesYear 
                                        data={topLists?.[0]?.toplist_local?.sort((a:any, b:any) => {
                                            return b.total_acquired_year - a.total_acquired_year;
                                        })?.map((i: any) => {
                                            return {
                                                0: i?.list_name,
                                                1: general.formatPrice(i?.total_acquired_year, true, true, true) ?? "0",
                                                2: general.formatPercentage(i?.avg_interest_year?.toFixed(2) ?? 0)
                                            }
                                        }) ?? []}
                                    />
                                </GridCol>
                                <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* SalesmanSalesMonth */}
                                    <SalesmanSalesMonth 
                                        data={topLists?.[0]?.toplist_local?.sort((a:any, b:any) => b?.total_acquired_month - a?.total_acquired_month)?.map((i: any) => {
                                            return {
                                                0: i?.list_name,
                                                1: general.formatPrice(i?.total_acquired_month, true, true, true) ?? "0",
                                                2: general.formatPercentage(i?.avg_interest_month?.toFixed(2) ?? 0)
                                            }
                                        }) ?? []}
                                    />
                                </GridCol>
                            </>
                        }
                        {topLists?.length == 0 || topLists?.[0]?.toplist_million_pmf?.length == 0 ? null :
                            <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* PointsOverall */}
                                <PointsOverall 
                                    data={topLists?.[0]?.toplist_million_pmf?.sort((a:any, b:any) => b?.points_year - a?.points_year)?.map((i: any) => {
                                        return {
                                            0: i?.list_name,
                                            1: i?.list_orgname,
                                            2: general.formatPrice(i?.points_year, true, true, true) ?? "0"
                                        }
                                    }) ?? []}
                                />
                            </GridCol>
                        }
                        {/* {topLists?.[0]?.toplist_million_pmf?.length == 0 ? null :
                            <GridCol className={styles.gridcol} lg="6" md="12" sm="12">PointsRegionYear
                                <PointsRegionYear 
                                    data={topLists?.[0]?.toplist_million_pmf?.sort((a:any, b:any) => b?.points_year - a?.points_year)?.map((i: any) => {
                                        return {
                                            0: i?.list_name,
                                            1: i?.list_orgname,
                                            2: i?.points_year
                                        }
                                    }) ?? []}
                                />
                            </GridCol>
                        } */}
                        {topLists?.length == 0 || topLists?.[0]?.toplist_poengmnd_pmf?.length == 0 ? null :
                            <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* PointsRegionYear */}
                                <PointsMonth 
                                    data={topLists?.[0]?.toplist_poengmnd_pmf?.sort((a:any, b:any) => b?.points_month - a?.points_month)?.map((i: any) => {
                                        return {
                                            0: i?.list_name,
                                            1: i?.list_orgname,
                                            2: general.formatPrice(i?.points_month, true, true, true) ?? "0"
                                        }
                                    }) ?? []}
                                />
                            </GridCol>
                        }

                        {topLists?.length == 0 || topLists?.[0]?.toplist_tipatopp_pmf?.length == 0 ? null :
                            <>
                                <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* PointsRegionYear */}
                                    <Top10Dealers 
                                        data={topLists?.[0]?.toplist_tipatopp_pmf?.sort((a:any, b:any) => b?.points_year - a?.points_year)?.map((i: any) => {
                                            return {
                                                0: i?.list_orgname,
                                                1: general.formatPrice(i?.points_year, true, true, true) ?? "0"
                                            }
                                        }) ?? []}
                                    />
                                </GridCol>
                            </>
                        }
                    </GridRow>
                    <GridRow className={styles.gridrow}>
                        {/* CARAVAN */}
                        {topLists?.length == 0 || topLists?.[0]?.toplist_caravan?.length == 0 ? null :
                            <>
                                <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* PointsCaravanYear */}
                                    <PointsTableDynamic 
                                        title="Poengoversikt hittil i år"
                                        columns={["Selger", "Forhandler", "Poeng"]}
                                        data={topLists?.[0]?.toplist_caravan?.filter?.((i: any) => i?.points_year != undefined)?.sort((a:any, b:any) => b?.points_year - a?.points_year)?.map((i: any) => {
                                            return {
                                                0: i?.list_name,
                                                1: i?.list_orgname,
                                                2: general.formatPrice(i?.points_year, true, true, true) ?? "0"
                                            }
                                        }) ?? []}
                                    />
                                </GridCol>
                                <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* PointsCaravanMonth */}
                                    <PointsTableDynamic 
                                        title="Poengoversikt inneværende måned"
                                        columns={["Selger", "Forhandler", "Poeng"]}
                                        data={topLists?.[0]?.toplist_caravan?.filter?.((i: any) => i?.points_month != undefined)?.sort((a:any, b:any) => b?.points_month - a?.points_month)?.map((i: any) => {
                                            return {
                                                0: i?.list_name,
                                                1: i?.list_orgname,
                                                2: general.formatPrice(i?.points_month, true, true, true) ?? "0"
                                            }
                                        }) ?? []}
                                    />
                                </GridCol>
                            </>
                        }

                        {/* BOAT */}
                        {topLists?.length == 0 || topLists?.[0]?.toplist_boat?.length == 0 ? null :
                            <>
                                <GridCol className={styles.gridcol} lg="6" md="12" sm="12">{/* PointsBoatYear */}
                                    <PointsTableDynamic 
                                        title="Topp 5 forhandlere i din kategori inneværende år"
                                        columns={["Forhandler", "Poeng"]}
                                        data={topLists?.[0]?.toplist_boat?.filter?.((i: any) => i?.points_year != undefined)?.sort((a:any, b:any) => b?.points_year - a?.points_year)?.map((i: any) => {
                                            return {
                                                0: i?.list_orgname,
                                                1: general.formatPrice(i?.points_year, true, true, true) ?? "0"
                                            }
                                        }) ?? []}
                                    />
                                </GridCol>
                            </>
                        }
                    </GridRow>
                </Grid>
            }
            { keydata == undefined && volume == undefined && cases == undefined ? 
                <div className={styles.blurtext}>
                    <h3>Det ser ikke ut til at statistikk er tilgjengeliggjort for din forhandler. Kontakt din salgssjef ved spørsmål.</h3>
                </div>
            : null }
        </div>
    );
}

export default Statistics;