import React, { useState, useContext, createContext, useEffect } from "react";
import { Agreement } from "./model/Agreement";
import { Dealer } from "./model/Dealer";
import { HelpDisplay } from "./model/HelpDisplay";
import { InfoDisplay } from "./model/InfoDisplay";
import { Insuranceapplication } from "./model/Insuranceapplication";
import { Loanapplication } from "./model/Loanapplication";
import { User } from "./model/User";

const axios = require('axios').default;

class Settings {
    whitelabel!: {
        total: boolean;
        logoLight: string;
        logoDark: string;
        colorPrimary: string;
        colorPrimaryLight: string;
        colorSecondary: string;
        colorSecondaryLight: string;
    };
};

class Flags {
    [key: string]: boolean;
}

const authContext = createContext({
    user: new User() || undefined,
    setUser: (user: User) => {},

    dealers: ([] as Array<Dealer>) || undefined,
    setDealers: (dealer: Array<Dealer>) => {},

    selectedDealer: undefined as any,
    setSelectedDealer: (dealer: Dealer) => {},

    settings: new Settings() || undefined,
    setSettings: (settings: Settings) => {},

    flags: new Flags() || undefined,
    setFlag: (key: string, value: boolean) => {},
    setFlags: (flags: Flags) => {},

    selectedAgreement: undefined as any,
    setSelectedAgreement: (agreement: Agreement | undefined) => {},

    //currentLoanapplication: undefined as any,
    //setCurrentLoanapplication: (loanapplication: Loanapplication | undefined) => {},

    currentInsuranceapplication: undefined as any,
    setCurrentInsuranceapplication: (insuranceapplication: Insuranceapplication | undefined) => {},

    uniqueCategories: [] as Array<any>,
    setUniqueCategories: (categories: Array<any>) => {},

    helpDisplay: undefined as any,
    setHelpDisplay: (help: HelpDisplay | undefined) => {},

    infoDisplay: undefined as any,
    setInfoDisplay: (help: InfoDisplay | undefined) => {},
    
    loading: false,
    setLoading: (loading: boolean) => {},

    signin: (user: any, password: any): Promise<Object> => { return new Promise<Object>((resolve, reject) => { resolve({}); }); },
    signout: (): Promise<void> => { return new Promise<void>((resolve, reject) => { resolve(); }); },
});

export function ProvideAuth({ children }: any) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState<User>({
        jwt: "",
        RefreshToken: "",
        userdata: undefined,
        DefaultDealerSequence: undefined
    });
    const [dealers, setDealers] = useState<Array<Dealer>>([]);
    const [selectedDealer, setSelectedDealer] = useState<Dealer | undefined>(new Dealer());
    const [selectedAgreement, setSelectedAgreement] = useState<Agreement | undefined>(undefined);
    const [helpDisplay, setHelpDisplay] = useState<HelpDisplay | undefined>(undefined);
    const [infoDisplay, setInfoDisplayTemp] = useState<InfoDisplay | undefined>(localStorage.getItem("infodisplay") ? JSON.parse(localStorage.getItem("infodisplay") as string) : undefined);
    // const [currentLoanapplication, setCurrentLoanapplication] = useState<Loanapplication | undefined>(undefined);
    const [currentInsuranceapplication, setCurrentInsuranceapplication] = useState<Insuranceapplication | undefined>(undefined);
    const [uniqueCategories, setUniqueCategories] = useState<Array<any>>([]);
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState<any>({ whitelabel: {} });
    const [flags, setFlags] = useState<Flags>(new Flags());

    function setFlag(key: string, value: boolean) {
        flags[key] = value;
        setFlags(flags);
    }
    
    function setInfoDisplay(infoDisplay: InfoDisplay | undefined) {
        if (infoDisplay != undefined ){
            localStorage.setItem("infodisplay", JSON.stringify(infoDisplay))
        }
        else {
            localStorage.removeItem("infodisplay")
        }
        setInfoDisplayTemp(infoDisplay)
    }
    
    if (user.jwt == "" && localStorage.getItem("logindata") != null) {
        setUser(JSON.parse(localStorage.getItem("logindata") || ""));
    }

    async function signin(email: string, password: string) {
        return new Promise<Object>(async (resolve, reject) => {
            let logindata: any;

            await axios.post('/api/login', {
                username: email,
                password: password
            })
            .then(function (response: any) {
                if (response.status === 503) {
                    reject(response);
                }
                logindata = response.data;
                logindata.jwt = logindata.JWT;
                localStorage.setItem('logindata', JSON.stringify(logindata));
                setUser(logindata);
                resolve(logindata);
            })
            .catch(function (error: any) {
                reject(error);
            });
        });
    };

    function signout() {
        return new Promise<void>(async (resolve, reject) => {
            localStorage.removeItem("logindata");
            localStorage.removeItem("infodisplay");
            localStorage.removeItem("helpdisplay");
            localStorage.removeItem("selecteddealer");
            localStorage.removeItem("selectedagreement");
            localStorage.removeItem("selectedDealerSequence");
            setUser(new User());
            // setCurrentLoanapplication(new Loanapplication());
            setHelpDisplay(undefined);
            setInfoDisplay(undefined);
            setSelectedDealer(undefined);
            resolve();
        });
    };

    useEffect(() => {
        function checkUserData() {
            const item = localStorage.getItem('logindata');
            if (item) {
                setUser({
                    ...user,
                    ...JSON.parse(item)
                });
            }
        }
      
        window.addEventListener('storage', checkUserData)
      
        return () => {
            window.removeEventListener('storage', checkUserData)
        }
    }, [])

    useEffect(() => {
        document.documentElement.style.setProperty('--color-primary', settings.whitelabel?.colorPrimary || "#002776");
        document.documentElement.style.setProperty('--color-primary-light', settings.whitelabel?.colorPrimaryLight || "#005aa4");
        document.documentElement.style.setProperty('--color-secondary', settings.whitelabel?.colorSecondary || "#338569");
        document.documentElement.style.setProperty('--color-secondary-light', settings.whitelabel?.colorSecondaryLight || "#7eab9b");

        (window as any).colorPrimary = settings.whitelabel?.colorPrimary || "#002776";
        (window as any).colorPrimaryLight = settings.whitelabel?.colorPrimaryLight || "#005aa4";
        (window as any).colorSecondary = settings.whitelabel?.colorSecondary || "#338569";
        (window as any).colorSecondaryLight = settings.whitelabel?.colorSecondaryLight || "#7eab9b";
    }, [settings.whitelabel]);

    return {
        user,
        setUser,
        dealers,
        setDealers,
        selectedDealer,
        setSelectedDealer,
        selectedAgreement,
        setSelectedAgreement,
        // currentLoanapplication,
        // setCurrentLoanapplication,
        currentInsuranceapplication,
        setCurrentInsuranceapplication,
        uniqueCategories,
        setUniqueCategories,
        helpDisplay,
        setHelpDisplay,
        infoDisplay,
        setInfoDisplay,
        loading,
        setLoading,
        signin,
        signout,
        settings,
        setSettings,
        flags,
        setFlag,
        setFlags,
    };
}