import React, { useEffect } from "react";
import { useAuth } from "../../../Auth";
import Notifications from "./MessageComponents/Notifications";
import { toast } from 'react-toastify';
import moment from "moment";

import styles from './Messages.module.scss';

function Messages(props: MessagesProps) {
    const auth = useAuth();

    const [seenNewMessages, setSeenNewMessages] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState(0);

    const [newMessages, setNewMessages] = React.useState([]);

    const [displayUrgentMsg, setDisplayUrgentMsg] = React.useState(0);
    const [acceptingUrgentMsg, setAcceptingUrgentMsg] = React.useState(false);

    const notificationsRef = React.useRef(null);
    
    function getHumanTimeSince(date: Date) {
        const now = moment();
        const then = moment(date);
        const diff = now.diff(then, "minutes");
        let answer = "";

        if (diff < 60) {
            answer = diff + " minutter siden";
        } else if (diff < 1440) {
            answer = Math.floor(diff / 60) + " timer siden";
        } else if (diff < 10080) {
            answer = Math.floor(diff / 1440) + " dager siden";
        } else if (diff < 43200) {
            answer = Math.floor(diff / 10080) + " uker siden";
        } else if (diff < 525600) {
            answer = Math.floor(diff / 43200) + " måneder siden";
        } else {
            answer = Math.floor(diff / 525600) + " år siden";
        }
        
        return answer;
    }

    useEffect(() => {
        if (newMessages.filter((m: any) => m.urgent != true).length > 0 && !seenNewMessages) {
            toast.info("Du har " + newMessages.filter((m: any) => m.urgent != true).length + " nye meldinger! Klikk her for å lese.", {
                onClick: () => {
                    setOpen(true);
                    setSeenNewMessages(true);
                },
                position: "top-center",
            });
        }
        setAcceptingUrgentMsg(false);
    }, [newMessages, seenNewMessages]);

    return (
        <>
            <div className={styles.messages + " " + "noprint"}>
                { auth.user != undefined && auth.user.jwt != "" ? 
                    <>
                        {!(newMessages.filter((n: any) => n.urgent).length > 0) ?
                            <div
                                className={styles.floatingbutton}
                                onClick={() => {
                                    if (!open) {
                                        setSeenNewMessages(true);
                                    }
                                    setOpen(!open);
                                }}
                            >
                                { newMessages.length > 0 ? <span className={styles.newmessages}>{newMessages.length}</span> : null }
                                <span className="material-symbols-outlined">
                                    notifications_active
                                </span>
                            </div>
                        : null }
                        <div className={styles.container} style={{ visibility: open ? "visible" : "hidden" }}>
                            <div className={styles.tabs}>
                                <div className={styles.tab + " " + (activeTab == 0 ? styles.active : "")} onClick={() => setActiveTab(0)}>
                                    <h3>Notifikasjoner</h3>
                                </div>
                                {/* <div className={styles.tab + " " + (activeTab == 1 ? styles.active : "")} onClick={() => setActiveTab(1)}>
                                    <h3>Chat</h3>
                                </div> */}
                            </div>
                            <div className={styles.componentcontainer}>
                                { activeTab == 0 &&
                                    <Notifications
                                        ref={notificationsRef}
                                        onLoad={(messages) => setNewMessages(messages)}
                                    />
                                }
                            </div>
                        </div>
                    </>
                : null }
            </div>
            {newMessages.filter((n: any) => n.urgent).length > 0 ? 
                <div className={styles.fsnotificationscontainer}>
                    <div className={styles.fsnotifications}>
                        {newMessages.filter((n: any) => n.urgent).map((n: any, index: number) =>
                            displayUrgentMsg == index ?
                                <div className={styles.fsnotification} key={index}>
                                    <div className={styles.header}>
                                        <div className={styles.icon}>
                                            <span className="material-symbols-outlined">lightbulb_circle</span>
                                        </div>
                                        <div className={styles.content}>
                                            <h3>{n.Title}</h3>
                                        </div>
                                        <div className={styles.time}>
                                            <p>{getHumanTimeSince(n.Published)}</p>
                                        </div>
                                    </div>
                                    <div className={styles.body}>
                                        <p>{n.Message}</p>
                                    </div>
                                    <div className={styles.actions}>
                                        <button
                                            className={styles.accept}
                                            disabled={acceptingUrgentMsg}
                                            onClick={() => {
                                                setAcceptingUrgentMsg(true);
                                                if (notificationsRef.current != null) {
                                                    (notificationsRef.current as any)?.archiveNotification(n.NotificationID);

                                                    // If this is the last urgent message, show the previous message
                                                    if (displayUrgentMsg == newMessages.filter((n: any) => n.urgent).length - 1) {
                                                        setDisplayUrgentMsg(displayUrgentMsg - 1);
                                                    }
                                                }
                                            }}
                                        >Merk som lest</button>
                                    </div>
                                </div>
                            : null
                        )}
                        { newMessages.filter((n: any) => n.urgent).length > 1 ?
                            <>
                                {!(displayUrgentMsg == newMessages.filter((n: any) => n.urgent).length - 1) ?
                                    <div
                                        className={styles.nextnotification + " " + (displayUrgentMsg == newMessages.filter((n: any) => n.urgent).length - 1 ? styles.disabled : "")}
                                        onClick={() => {
                                            if (displayUrgentMsg < newMessages.filter((n: any) => n.urgent).length - 1) {
                                                setDisplayUrgentMsg(displayUrgentMsg + 1)
                                            }
                                        }}
                                    >
                                        <p>Neste<span className="material-symbols-outlined">arrow_forward</span></p>
                                    </div>
                                : null}
                                {displayUrgentMsg > 0 ?
                                    <div
                                        className={styles.prevnotification + " " + (displayUrgentMsg == 0 ? styles.disabled : "")}
                                        onClick={() => {
                                            if (displayUrgentMsg > 0) {
                                                setDisplayUrgentMsg(displayUrgentMsg - 1)
                                            }
                                        }}
                                    >
                                        <p><span className="material-symbols-outlined">arrow_back</span>Forrige</p>
                                    </div>
                                : null}
                            </>
                        : null}
                    </div>
                </div>
            : null }
            <div className={styles.messagesbackdrop + " " + "noprint"} style={{ display: open || (newMessages.filter((m: any) => m.urgent).length > 0) ? "block" : "none" }} onClick={() => setOpen(false)}></div>
        </>
    );
}

class MessagesProps {
    
}

export default Messages;