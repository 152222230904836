import React, { useEffect } from "react";

import styles from './LoanInsuranceCard.module.scss';
import ContentCard from "../../../../Shared/ContentCard/ContentCard";
import { Input, InputGroup, RadioButtonInputGroup, RadioSwitch, Checkbox } from "@sb1/ffe-form-react";
import Tooltip from '@mui/material/Tooltip';
import { Loanapplication as LoanapplicationModel} from "../../../../../model/Loanapplication";
import { useLoanapplication } from "../../../../../LoanapplicationContext";
import { useAuth } from "../../../../../Auth";
import general from "../../../../../functions/general";

function LoanInsuranceCard(props: LoanInsuranceCardProps) {
    const auth = useAuth();
    const loanapplication = useLoanapplication();

    let tooltipClasses = "disabledAgreementTooltip" + " " + localStorage.getItem("theme");

    const [limitExceeded, setLimitExceeded] = React.useState(false);

    useEffect(() => {
        if((loanapplication?.calculationResult?.TotalPeriodicAmount + loanapplication?.calculationResult?.TotalPeriodicAmountVat) > auth.selectedDealer.DebtInsuranceLimit) {
            setLimitExceeded(true);
            loanapplication.setDebtInsuranceRequested(false);
        } else {
            setLimitExceeded(false);
        }
    }, [loanapplication]);

    return (
        <ContentCard disabled={props.disabled} open={props.open} title="Betalingsforsikring" className={styles.loaninsurancecard}>
            <InputGroup
                className={styles.checkboxinputgroup}
            >
                <Tooltip
                    disableHoverListener={!props.disabled && auth.selectedDealer.DebtInsuranceEnabled && !limitExceeded}
                    disableTouchListener={!props.disabled && auth.selectedDealer.DebtInsuranceEnabled && !limitExceeded}
                    disableFocusListener={!props.disabled && auth.selectedDealer.DebtInsuranceEnabled && !limitExceeded}
                    leaveDelay={0}
                    TransitionProps={{ 
                        timeout: 0
                    }}
                    title={limitExceeded ? "Maksgrense er nådd." : "Betalingsforsikring er ikke tilgjengelig."}
                    classes={{ tooltip: tooltipClasses }}
                >
                    <div className={styles.checkboxcontainer}>
                        <Checkbox
                            name="debtInsurance"
                            disabled={props.disabled || !auth.selectedDealer.DebtInsuranceEnabled || limitExceeded}
                            inline={false}
                            checked={loanapplication.debtInsuranceRequested}
                            onChange={e => {
                                loanapplication.setDebtInsuranceRequested(e.target.checked);
                            }}
                        >
                            Legg på betalingsforsikring for søknaden 
                        </Checkbox>
                        &nbsp;
                        <p>Pris: {loanapplication?.calculationResult?.TotalPeriodicAmount == 0 || loanapplication?.calculationResult?.TotalPeriodicAmount == undefined ? "N/A" : general.formatPrice((loanapplication?.calculationResult?.TotalPeriodicAmount ?? 0) * (auth?.selectedDealer?.DebtInsurancePercentage ?? 0), false, true)} pr. termin.</p> 
                    </div>
                </Tooltip>
            </InputGroup>
        </ContentCard>
    );
}

interface LoanInsuranceCardProps {
    currentLoanapplication: LoanapplicationModel | undefined;
    disabled: boolean;
    open: boolean;
}

export default LoanInsuranceCard;