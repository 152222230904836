/**
 * Used to ensure that a css file is loaded
 * @param styleSheet
 * @param retryInterval 
 */
export const ensureCssLoaded = (styleSheet: string, retryInterval?: Number) => {
    var func = () => {
      let el: any = document.createElement('style');
      el.textContent = '@import "' + styleSheet + '"';
      document.getElementsByTagName('head')[0].appendChild(el);
      try {
        let rule = el.sheet.cssRule;
        console.log(`${styleSheet} was loaded`);
      } catch (err) {
        console.log(`${styleSheet} failed to load`);
        if (retryInterval) {
          setTimeout(func, retryInterval as number);
        } 
      }
    };
    func();
  };