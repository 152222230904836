import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Variables.module.scss';
import './ffe.css';
import { replaceEnvVariables } from './functions/config';
import { ensureCssLoaded } from './functions/helpers';

replaceEnvVariables();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App />
);

reportWebVitals();
ensureCssLoaded('google_material_icons.css', 100);